import { Link as RouterLink } from "react-router-dom";


const CloseBlog = () => {
  return (
    <RouterLink
      to="/blog"
      draggable="false"
      className="flex items-center lg:text-lg text-xs font-jetbrains-regular border-solid border-black border-2 rounded-md lg:p-3 m-4
                hover:scale-110 transition-transform select-none w-fit"
    >
      Close Blog
    </RouterLink>
  );
}

export default CloseBlog;