import { BreakText, ContentText, TitleText } from "../../components/TextSizes"


const BlogSpotifyLocalFiles = () => {
  return (
    <div className="flex w-screen bg-transparent">
      <div className="lg:w-3/4 mt-12 bg-transparent w-1/2 mx-auto">
        <TitleText author={"anirud"} date={"021125"} className="min-h-2.5">how to put unreleased songs into your spotify playlist</TitleText>
        <BreakText/><BreakText/><BreakText/><BreakText/><BreakText/>
        <ContentText>
          i learned this in early high school i think and i added
          every single unreleased song i could on my spotify immediately.
          this is how you do it
          <BreakText/>
          <div className="w-3/4 mx-auto">
            <ul>
              1. get an mp3 of any song that you want to import into spotify
            </ul>
            <BreakText/>
            <ul>
              2. open spotify (desktop application)
            </ul>
            <BreakText/>   
            <ul>
              3. click your icon in the top right corner and go to settings
            </ul>
            <BreakText/>   
            <ul>
              4. go to the "show songs from" section and tick on the downloads slider.
              you can also add source like I did and make your own folder containing a
              list of the unreleased songs you downloaded
            </ul>
            <BreakText/>   
            <ul>
              5. in "your library" on the left, you should see a playlist called "local files"
            </ul>
            <BreakText/>   
            <ul>
              6. if you don't see your mp3, double check you have it in the right folder that is
              being tracked by spotify or restart the application so it can sync again
            </ul>
            <BreakText/>   
          </div>
          <BreakText/>
          bonus if you connect your laptop and phone to the same wifi, you can get the songs
          on your phone.

          now you can listen to all your favorite songs on spotify without interuptions 
        </ContentText>
      </div>
    </div>
  )
}

export default BlogSpotifyLocalFiles;