import { useState, useEffect } from "react";

const TypingText = ({ text }) => {
  const [showCursor, setShowCursor] = useState(true);
  const [subtitleTextSplice, setSubtitleTextSplice] = useState(0);

  useEffect(() => {
    const cursorInterval = setInterval(() => {
      setShowCursor((prev) => !prev);
    }, 500);

    const subtitleInterval = setInterval(() => {
      if (subtitleTextSplice < text.length) {
        setSubtitleTextSplice((prev) => prev + 1);
      } else {
        clearInterval(subtitleInterval);
      }
    }, 20);
   
    return () => {
      clearInterval(cursorInterval);
      clearInterval(subtitleInterval);
    };
  }, [subtitleTextSplice, text]);

  return (
    <>
      {text.slice(0, subtitleTextSplice)}
      <span className={`cursor ${showCursor ? "visible" : "invisible"}`}>|</span>
    </>
  )
}

export default TypingText;