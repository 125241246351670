import Header from "../frontpage/Header";
import BlogGallery from "./Gallery";
import BlogHeroSection from "./HeroSection"

const Blog = () => {
  return (
    <div className="w-screen">
      <Header />    
      <BlogHeroSection />
      <BlogGallery /> 
    </div>
  )
}


export default Blog;