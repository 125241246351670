import TypingText from "../components/Typing";

const subtitleText = "Ranking a list of my favorite songs and albums throughout the years... (work in progress)";

const HeroSection = () => {

  return (
    <div className="flex w-screen h-screen bg-transparent">
      <div className="lg:w-3/4 mt-24 bg-transparent w-1/2 mx-auto">
        <div className="lg:text-7xl text-5xl font-jetbrains-bolder py-10">
          Secret Music Page
        </div>
        <div className="lg:text-3xl text-xl font-jetbrains-regular pb-10">
          <TypingText text={subtitleText}/>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
