import TypingText from "../components/Typing";

const subtitleText = "Lifehacks and knowledge I have been accumulating throughout the years...";

const BlogHeroSection = () => {

  return (
    <div className="flex w-screen bg-transparent">
      <div className="lg:w-3/4 mt-24 bg-transparent w-1/2 mx-auto">
        <div className="lg:text-7xl text-5xl font-jetbrains-bolder py-10">
          Secret Blog Page
        </div>
        <div className="lg:text-3xl text-xl font-jetbrains-regular pb-10 min-h-32">
          <TypingText text={subtitleText}/>
        </div>
      </div>
    </div>
  );
};

export default BlogHeroSection;
