import { ContentText } from "../components/TextSizes";
import { Link as RouterLink } from "react-router-dom";

const blogStyle = "border-solid border-black border-2 rounded-md m-5 p-1"
const BlogGallery = () => {
  return (
    <div className="flex w-screen bg-transparent">
      <div className="lg:w-3/4 mt-24 bg-transparent w-1/2 mx-auto">
        <div className={blogStyle}>
          <RouterLink
            to="/blog/spotify-local-files"
            draggable="false"
            >
            <div className="flex items-center justify-between">
              <ContentText>
                -{">"}  how to put unreleased songs into your spotify playlist
              </ContentText>
              <ContentText>
                021125
              </ContentText>
            </div>
          </RouterLink>
        </div>
        <div className={blogStyle}>
          <RouterLink
            to="/blog/command-d"
            draggable="false"
          >
            <ContentText>
              -{">"} use cmd+d in your coding editor
            </ContentText>
          </RouterLink>
        </div>
      </div>
    </div>
  );
};

export default BlogGallery;