import React, { useState  } from "react";
import * as THREE from "three";

const generateHeart = () => {
  const heartShape = new THREE.Shape();

  heartShape.moveTo(2, 2);
  heartShape.bezierCurveTo(2, 2, 1.6, 0, 0, 0);
  heartShape.bezierCurveTo(-2.4, 0, -2.4, 2.8, -2.4, 2.8);
  heartShape.bezierCurveTo(-2.4, 4.4, -0.8, 6, 2, 7.6);
  heartShape.bezierCurveTo(4.8, 6, 6.4, 4.4, 6.4, 2.8);
  heartShape.bezierCurveTo(6.4, 2.8, 6.4, 0, 4, 0);
  heartShape.bezierCurveTo(2.8, 0, 2, 2, 2, 2);

  const extrudeSettings = {
    bevelEnabled: true,
    bevelSegments: 1,
    steps: 2,
    bevelSize: 1,
    bevelThickness: 1,
  };

  const heartGeometry = new THREE.ExtrudeGeometry(
    heartShape,
    extrudeSettings
  );
  const heartMaterial1 = new THREE.MeshPhongMaterial({ color: 0xff4033 });
  const heartMaterial2 = new THREE.MeshPhongMaterial({ color: 0xc51104 });
  const heartMaterial3 = new THREE.MeshPhongMaterial({ color: 0xfe251b });
  const heartMaterial4 = new THREE.MeshPhongMaterial({ color: 0xff4033 });
  const heartMaterialList = [
    heartMaterial1,
    heartMaterial2,
    heartMaterial3,
    heartMaterial4,
  ];

  return new THREE.Mesh(
    heartGeometry,
    heartMaterialList[Math.floor(Math.random() * 4)]
  );
}

const ValentinesDay = () => {
  const [isVisible, setIsVisible] = useState(true);

  const handleYesClick = async () => {
    setIsVisible(false);

    const frontpageElement = document.querySelector(".h-0");
    frontpageElement.addEventListener("transitionend", handleTransitionEnd);

    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(
      75,
      window.innerWidth / window.innerHeight,
      0.1,
      10000
    );
    const renderer = new THREE.WebGLRenderer();
    renderer.setSize(window.innerWidth, window.innerHeight);
    scene.background = new THREE.Color(0xffffff);
    const canvasContainer = document.createElement("div");
    canvasContainer.classList.add(
      "fixed",
      "top-0",
      "left-0",
      "pointer-events-none"
    );
    canvasContainer.appendChild(renderer.domElement);
    document.body.appendChild(canvasContainer);

    const light1 = new THREE.DirectionalLight(0xffffff, 10);
    light1.position.set(0, 0, 1);
    scene.add(light1);

    const light2 = new THREE.PointLight(0xff0000, 1, 10);
    light2.position.set(0, 0, 500);
    scene.add(light2);

    camera.position.z = 100;

    let allHearts = [];
    const numberOfHearts = 100;
    const gridSpacing = 200;
    const depthSpacing = 500;

    const makeHeartsFront = (currZ) => {
      for (let i = 0; i < numberOfHearts; i++) {
        const x = (i % 10) * gridSpacing - (4.5 * gridSpacing);
        const y = Math.floor(i / 10) * gridSpacing - (4.5 * gridSpacing);

        const currHeart = generateHeart()
        currHeart.rotateX(135);
        currHeart.rotateY(Math.random() * 360);
        currHeart.position.set(
          x + (Math.random() * 100 - 50),
          y + (Math.random() * 100 - 50),
          currZ - 2000 + (Math.random() * depthSpacing - depthSpacing / 2)
        );
        allHearts.push(currHeart);
        scene.add(currHeart);
      }
    };

    makeHeartsFront(1500);
    makeHeartsFront(500);

    let seenZ = new Set();

    const loadImage = async (imageLink) => {
      const { default: image } = await import(`./sami_pics/${imageLink}`);
      return image;
    };

    const moveInCamera = (event, imagePlane) => {
      console.log("click");
      imagePlane.position.set(
        camera.position.x,
        camera.position.y,
        camera.position.z - 500
      );
    };

    const imageOnScreen = async (imageLink, x, y, z) => {
      const dynamicImage = await loadImage(imageLink);

      const texture = new THREE.TextureLoader().load(dynamicImage);

      const planeGeometry = new THREE.PlaneGeometry(150, 150);
      const material = new THREE.MeshBasicMaterial({ map: texture });

      const imagePlane = new THREE.Mesh(planeGeometry, material);
      imagePlane.position.set(x, y, z);
      imagePlane.addEventListener("mousedown", (event) =>
        moveInCamera(event, imagePlane)
      );
      scene.add(imagePlane);
    };

    const imageContext = require.context(
      "./sami_pics",
      false,
      /\.(jpg|jpeg|png|gif)$/
    );
    const relativeFilePaths = imageContext.keys().map((key) => {
      const cleanKey = key.replace("./", "");
      require(`./sami_pics/${cleanKey}`);
      return cleanKey;
    });

    let seenSet = new Set();
    let toSeeZ = 2000;
    let windowWrap = 0;
    const animate = () => {
      requestAnimationFrame(animate);

      if (window.scrollY >= 100) {
        windowWrap++;
        window.scrollTo(0, 0);
      }
      camera.position.z = window.scrollY * -0.5 + -50 * windowWrap;

      if (seenSet.size >= 120) {
        seenSet = new Set();
      }

      if (camera.position.z <= toSeeZ) {
        toSeeZ -= 500;
        let delta_z = 0;
        for (let y = -200; y <= 200; y += 200) {
          for (let x = -200; x <= 200; x += 200) {
            if (x !== 0 || y !== 0) {
              let imageIndex = Math.floor(
                Math.random() * relativeFilePaths.length
              );
              while (seenSet.has(imageIndex)) {
                imageIndex = Math.floor(
                  Math.random() * relativeFilePaths.length
                );
              }
              seenSet.add(imageIndex);
              imageOnScreen(
                relativeFilePaths[imageIndex],
                camera.position.x + x - 50,
                camera.position.y + y,
                toSeeZ - 2000 - delta_z * (Math.random() * 50 + 150)
              );
              delta_z -= 1;
            }
          }
        }
      }

      if (
        !seenZ.has(camera.position.z) &&
        Math.abs(Math.floor(camera.position.z)) % 100 === 0
      ) {
        makeHeartsFront(camera.position.z);
        seenZ.add(camera.position.z);
      }

      for (let heart of allHearts) {
        heart.rotation.y += 0.02;
      }

      renderer.render(scene, camera);
    };

    animate();

    return () => {
      document.body.removeChild(renderer.domElement);
    };
  };

  const handleTransitionEnd = () => {
    const frontpageElement = document.querySelector(".h-0");

    if (frontpageElement && frontpageElement.parentNode) {
      frontpageElement.removeEventListener(
        "transitionend",
        handleTransitionEnd
      );
      frontpageElement.parentNode.removeChild(frontpageElement);
    }

    let brDocument = document.createElement("div");
    for (let i = 0; i < 100; i++) {
      const br = document.createElement("br");
      brDocument.appendChild(br);
    }
    document.body.appendChild(brDocument);
  };

  const handleNoClick = () => {
    const titleText = document.getElementById("title-text");
    const oldText = titleText.innerHTML;
    titleText.innerHTML = oldText + " please";
  };

  return (
    <div
      className={`h-0 ${
        isVisible
          ? "opacity-100"
          : "opacity-0 transition-opacity overflow-hidden"
      }`}>
      <div
        id="title-text"
        className="font-comic text-8xl text-center mt-56 select-none">
        Will you be my valentine?
      </div>
      <div className="font-comic text-8xl mt-24">
        <div className="flex justify-center">
          <button className="select-none" onClick={handleYesClick}>
            Yes
          </button>
          <div className="mx-10"></div>
          <div
            className="hover:cursor-pointer select-none"
            onClick={handleNoClick}>
            No
          </div>
        </div>
      </div>
    </div>
  );
};

export default ValentinesDay;
