import CloseBlog from "./CloseBlog";

export const TitleText = ({ children, date, author }) => {
  return (
    <>
      <div className="lg:text-7xl text-5xl font-jetbrains-bolder py-10">
        {children}
      </div>
      <div className="font-jetbrains-regular">
        {`${author} ${date}\t`}
      </div>
      <div className="flex items-center justify-end">
        <div>
          ━━━━━━━━━━━━━━━━━ ━ ━ ━ 
        </div>
        <div>
          <CloseBlog/>
        </div>
      </div>
    </>
  );
};

export const BreakText = () => {
  return (
    <div className="w-full p-5"/>
  )
}

export const ContentText = ({ children }) => {
  return (
    <div className="text-black my-5 mx-5 text-lg font-jetbrains-regular">
      {children}
    </div>
  );
};
