import "../index.css";
import BayerLogo from "../assets/bayerwhitelogo.png";
import MarketMediumLogo from "../assets/mmwhitelogo.png";
import VarianLogo from "../assets/varianwhitelogo.png";
import TBCLogo from "../assets/theboringcompanylogo.png";

function ProjectCell({
  projectName,
  projectLink,
  projectLinkName,
  description,
  year,
  imagePNG,
}) {
  return (
    <a
      draggable="false"
      href={projectLink}
      target="_blank"
      rel="noreferrer"
      className="p-5 border-white rounded-md border-solid border-2 hover:scale-105 transition-transform max-h-1/2 min-w-1/5">
      <span className="block text-white text-center text-3xl font-jetbrains-bolder">
        {projectName}
      </span>
      <span className="block  text-white text-center text-sm font-jetbrains-regular">
        {projectLinkName}
      </span>
      <span className="block  text-white text-center text-xs font-jetbrains-regular">
        {year}
      </span>
      <img
        src={imagePNG}
        alt={`${projectName} Logo`}
        className="block mx-auto my-4 h-24 object-contain"
      />
      <span className="block text-white my-5 mx-5 text-lg text-center font-jetbrains-regular">
        {description}
      </span>
    </a>
  );
}

function Internships() {
  const projectData = [
    {
      projectName: "THE BORING COMPANY",
      projectLink: "https://www.boringcompany.com/",
      year: "Spring 2025",
      projectLinkName: "Software Engineer Intern",
      description:
        "Currently working on the Las Vegas Loop...",
      imagePNG: TBCLogo,
    },
    {
      projectName: "BAYER",
      projectLink: "https://www.bayer.com/en/",
      year: "Fall 2024",
      projectLinkName: "Software Engineer Intern",
      description:
        "Architected a LangChain workflow to streamline data retrieval from Bayer's Crop Science datalake, enabling seamless access for internal data scientists and researchers. Designed a reusable front-end component that interacts with a semantic layer, allowing non-technical stakeholders to generate data visualizations through natural language queries.",
      imagePNG: BayerLogo,
    },
    {
      projectName: "VARIAN",
      projectLink: "https://www.varian.com/",
      year: "Summer 2024",
      projectLinkName: "Software Engineer Intern",
      description:
      "Developed an code review pipeline that automatically reviews pull requests for errors using linters and generative AI. Created a semantic testing keyword searcher to improve testcase creation for automation team. Developed a software that can manipulate large amounts of DICOM medical files for testing.",
      imagePNG: VarianLogo,
    },
    {
      projectName: "MARKET MEDIUM",
      projectLink: "https://www.linkedin.com/company/marketmedium/",
      year: "Summer 2021, Summer 2022",
      projectLinkName: "Software Engineer Intern",
      description:
        "Developed a Java application to automate the parsing and identification of submittals in large-scale construction documents, saving engineers an average of 12 hours per week by eliminating manual search efforts. Designed and deployed a reusable React interface hosted on AWS, improving accessibility and enabling seamless retrieval of submittals for engineering teams.",
      imagePNG: MarketMediumLogo,
    },
  ];

  return (
    <div id="projects" className="w-screen h-auto bg-black text-white p-10">
      <div className="pb-10">
        <div className="text-5xl text-center font-jetbrains-regular select-none">
          INTERNSHIPS
        </div>
        <div className="pb-10 text-md text-center font-jetbrains-regular select-none">
          throughout the years
        </div>
        <div className="grid lg:grid-cols-2 grid-cols-1 gap-8">
          {projectData.map((skill, index) => (
            <ProjectCell
              key={index}
              projectName={skill.projectName}
              projectLink={skill.projectLink}
              projectLinkName={skill.projectLinkName}
              description={skill.description}
              imagePNG={skill.imagePNG}
              year={skill.year}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Internships;
