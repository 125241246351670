import "../index.css";

function ProjectCell({
  projectName,
  projectLink,
  projectLinkName,
  description,
  year,
}) {
  return (
    <a
      draggable="false"
      href={projectLink}
      target="_blank"
      rel="noreferrer"
      className="p-5 border-white rounded-md border-solid border-2 hover:scale-105 transition-transform max-h-1/2 min-w-1/5">
      <span className="block text-white text-center text-3xl font-jetbrains-bolder">
        {projectName}
      </span>
      <span className="block  text-white text-center text-sm font-jetbrains-regular">
        {projectLinkName}
      </span>
      <span className="block  text-white text-center text-xs font-jetbrains-regular">
        {year}
      </span>
      <span className="block text-white text-center mt-10 text-lg font-jetbrains-regular">
        {description}
      </span>
    </a>
  );
}

function Projects() {
  const projectData = [
    {
      projectName: "ZORRO",
      projectLink: "https://github.com/anirudlappathi/zorro",
      year: "Dec 2024",
      projectLinkName: "Zorro on Github",
      description:
        "Algorithmic cryptocurrency trading framework to simplify algorithmic trading on Robinhood. Simply use the @rc.run() decorator and Zorro run your algorithm every minute to automatically enter positions. Zorro also collects candlesticks for you locally to process.",
    },
    {
      projectName: "SKINMATCH",
      projectLink: "https://github.com/adeshkumar1/SkinMatch",
      year: "Feb 2024",
      projectLinkName: "SkinMatch on Github",
      description:
        'SkinMatch is a finalist project in the 2024 HackIllinois "HackKnights" advanced cohort. It is a novel personalized skincare routine generator made with custom trained YoloV8 models, a product finder made with Pinecone vector DB. We used React, TailwindCSS, and Flask to connect our front end to the API and we used Kaggle for our datasets.',
    },
    {
      projectName: "BURDOCK",
      projectLink: "https://github.com/anirudlappathi/burdock",
      year: "May 2024",
      projectLinkName: "Burdock on Github",
      description:
        "A completely local, high-speed, and open-source vector database built in C++. Utilized KDB-Trees to have fast CRUD and can be completely used in the local file system using Burdocks propriatary .brdk format.",
    },
    {
      projectName: "POSHIFY",
      projectLink: "https://github.com/anirudlappathi/Poshify",
      year: "Dec 2023",
      projectLinkName: "Poshify on Github",
      description:
        "AI powered fashion website to simplify the process of making outfits. You simply take pictures of their clothes and Poshify will use OpenCV and various data analysis and algorithmic techniques to generate outfits. The website uses these outfits to schedule your week.",
    },
    {
      projectName: "MATHCRASH",
      projectLink: "https://www.instagram.com/mathcrashcourses/",
      year: "Sep 2022",
      projectLinkName: "mathCrashCourses on Instagram",
      description:
        "'mathCrash' is a crash course page dedicated to one minute mathematic videos. The videos utilize the Python library 'Manim' which uses LaTeX to create elegant videos. The videos are then voiced over, edited, and touched up to be posted. The page has amassed 2500 followers.",
    },
    {
      projectName: "INFINITE WORDLE",
      projectLink: "https://github.com/anirudlappathi/infiniteWordle",
      year: "Feb 2022",
      projectLinkName: "Infinite Wordle on Github",
      description:
        'Being one of my first projects, I made an expanded "Wordle" game to scale it to more word lengths. The code is made in the Python console and uses color-coded letters to give a better visual experience.',
    },
  ];

  return (
    <div id="projects" className="w-screen h-auto bg-black text-white p-10">
      <div className="pb-10">
        <div className="text-5xl text-center font-jetbrains-regular select-none">
          PROJECTS
        </div>
        <div className="pb-10 text-md text-center font-jetbrains-regular select-none">
          click to view
        </div>
        <div className="grid lg:grid-cols-3 grid-cols-1 gap-8">
          {projectData.map((skill, index) => (
            <ProjectCell
              key={index}
              projectName={skill.projectName}
              projectLink={skill.projectLink}
              projectLinkName={skill.projectLinkName}
              description={skill.description}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Projects;
