import React, { useState, Suspense } from "react";
import "../index.css";
import Footer from "../frontpage/Footer";
import Header from "../frontpage/Header";

import NCSAPoster from "../assets/KGforSimGNNandKB-1-min.png";
import CPSC444Poster from "../assets/cpsc444-research-poster-1.png";
import CPSC499Poster from "../assets/cpsc499-capstone-poster-1.png";

const PosterImage = ({ PosterObject }) => {
  return (
    <>
      <img
        src={PosterObject}
        alt="Research Poster"
        className="block mx-auto my-4 object-cover"
      />
    </>
  );
};

function Research() {
  const [showNCSA1Poster, setShowNCSA1Poster] = useState(false);
  const [showNCSA2Poster, setShowNCSA2Poster] = useState(false);
  const [showNCSA3Poster, setShowNCSA3Poster] = useState(false);

  const toggleNCSA1Poster = () => {
    setShowNCSA1Poster((showNCSA1Poster) => !showNCSA1Poster);
  };
  const toggleNCSA2Poster = () => {
    setShowNCSA2Poster((showNCSA2Poster) => !showNCSA2Poster);
  };
  const toggleNCSA3Poster = () => {
    setShowNCSA3Poster((showNCSA3Poster) => !showNCSA3Poster);
  };

  return (
    <>
      <Header linkedLogo={true} hasHeaderButtons={false}  hasResumeButton={true}/>
      <div
        id="research"
        className="w-screen h-auto bg-white text-black p-10 pt-5">
        <div className="pb-10">
          <div className="grid lg:grid-cols-1 grid-cols-1 gap-8 p-5 border-black rounded-md mb-10 border-solid border-2 hover:scale-102 transition-transform">
            <a
              draggable="false"
              href="https://github.com/anirudlappathi/SimGNNwithKnowledgeGraph"
              target="_blank"
              rel="noreferrer"
              className="max-h-1/2 min-w-1/5">
              <span className="block text-black text-center text-3xl font-jetbrains-bolder">
                Using Knowledge Graphs to train Graph Neural Networks to find
                Data Similarity
              </span>
              <span className="block  text-black text-center text-xs font-jetbrains-regular">
                National Center for Supercomputing Applications
              </span>
              <span className="block  text-black text-center text-sm font-jetbrains-regular underline">
                Source Code (Github)
              </span>
              <span className="block  text-black text-center text-xs font-jetbrains-regular">
                Spring 2024
              </span>
            </a>
            <button
              onClick={toggleNCSA1Poster}
              className="font-jetbrains-regular block mx-auto p-5 border-black rounded-md border-solid border-2 hover:scale-102 transition-transform max-h-1/2 min-w-1/5">
              {showNCSA1Poster ? "Hide Poster" : "Show Poster"}
            </button>
            {showNCSA1Poster && (
              <Suspense fallback={<div>Loading Research Poster...</div>}>
                <PosterImage PosterObject={NCSAPoster} />
              </Suspense>
            )}
          </div>
          <div className="grid lg:grid-cols-1 grid-cols-1 gap-8 p-5 border-black rounded-md mb-10 border-solid border-2 hover:scale-102 transition-transform">
            <a
              draggable="false"
              href="https://github.com/anirudlappathi/SimGNNwithKnowledgeGraph"
              target="_blank"
              rel="noreferrer"
              className="max-h-1/2 min-w-1/5">
              <span className="block text-black text-center text-3xl font-jetbrains-bolder">
                Quantifying the Impact of Neonicotinoid Pesticides on Honeybee
                Colony Productivity and Honey Yields
              </span>
              <span className="block  text-black text-center text-xs font-jetbrains-regular">
                CPSC 499: Statistical Learning in Agriculture
              </span>
              <span className="block  text-black text-center text-xs font-jetbrains-regular">
                Fall 2024
              </span>
            </a>
            <button
              onClick={toggleNCSA2Poster}
              className="font-jetbrains-regular block mx-auto p-5 border-black rounded-md border-solid border-2 hover:scale-102 transition-transform max-h-1/2 min-w-1/5">
              {showNCSA2Poster ? "Hide Poster" : "Show Poster"}
            </button>
            {showNCSA2Poster && (
              <Suspense fallback={<div>Loading Research Poster...</div>}>
                <PosterImage PosterObject={CPSC499Poster} />
              </Suspense>
            )}
          </div>
          <div className="grid lg:grid-cols-1 grid-cols-1 gap-8 p-5 border-black rounded-md mb-10 border-solid border-2 hover:scale-102 transition-transform">
            <a
              draggable="false"
              href="https://github.com/anirudlappathi/SimGNNwithKnowledgeGraph"
              target="_blank"
              rel="noreferrer"
              className="max-h-1/2 min-w-1/5">
              <span className="block text-black text-center text-3xl font-jetbrains-bolder">
                Analyzing the Effects of Nitrogen Application and Soil Organic
                Matter on Corn Yield Variability
              </span>
              <span className="block  text-black text-center text-xs font-jetbrains-regular">
                CPSC 444: Spatial Analysis
              </span>
              <span className="block  text-black text-center text-xs font-jetbrains-regular">
                Fall 2024
              </span>
            </a>
            <button
              onClick={toggleNCSA3Poster}
              className="font-jetbrains-regular block mx-auto p-5 border-black rounded-md border-solid border-2 hover:scale-102 transition-transform max-h-1/2 min-w-1/5">
              {showNCSA3Poster ? "Hide Poster" : "Show Poster"}
            </button>
            {showNCSA3Poster && (
              <Suspense fallback={<div>Loading Research Poster...</div>}>
                <PosterImage PosterObject={CPSC444Poster} />
              </Suspense>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Research;
