import Footer from "../frontpage/Footer";
import Header from "../frontpage/Header";

const Resume = () => {
  return (
    <>
      <Header linkedLogo={true} hasHeaderButtons={false} hasResumeButton={false} />
      <img
        src="/files/alr.jpg"
        alt="Aniruddha Lappathi's Resume"
        style={{ width: "100%", height: "auto" }}
      />
      <Footer />
    </>
  );
};

export default Resume;
